.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#map {
    height: 100%;
    width: 100%
}


.map-container {
    height: 100%;
    width: 100%
}

#map-container {
    height: 100%;
    width: 100%
}

.mapboxgl-canvas {
    height: 100vh !important;
    width: 100% !important;
}


.map-outer {
    height: calc(100vh) !important;
    width: 100%;
}

.map-outer-monitoring {
    height: 100vh!important;
    width: 50%;
    background-color: #c0c0c0;
    position:fixed;
    top:0;
    z-index:100;
}

.map-container-monitoring {
    height: 100%;
    width: 100%
}

.map-outer {
    height: calc(100vh) !important;
    width: 100%;
}


html, body {
    height: 100vh;
    max-height: 100vh !important;
    overflow: hidden
}

a {
    color: #FFF;
    text-decoration: none;
}

a:hover {
    color: white
}

.mapboxgl-popup-content {
    background-color: #424242 !important;
    color: white;
    box-shadow: 10px 10px 5px 12px rgba(0, 0, 0, 0.5);
}


.mapboxgl-ctrl-geocoder--input {
    background-color: black !important;
    color: white !important;

}


body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.action-card:hover {
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

tr.MuiTableRow-root:hover {
    cursor: pointer;
}

button.MuiTab-root.Mui-selected {
    background-color: #334f59;
}

.mapboxgl-ctrl-top-right {
    margin-right: 36px;
}

.MuiToggleButton-secondary {
    color: white
}

#timeline-select {
    color: #b2b2b2;
}

#probability-select {
    color: #b2b2b2;
}

#timeline-select.Mui-selected {
    color: white;
}

#probability-select.Mui-selected {
    color: white
}

.popup-header {
    font-size: 18px;
    margin-bottom: 2px;
    margin-top: 2px;
}

.popup-body {
    font-size: 12px;
    margin: 0px;
    padding-top: 4px;
}

.mapboxgl-ctrl-icon {
    margin-top: 12px;
}
